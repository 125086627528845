import app from 'firebase/app';
import 'firebase/analytics';

const initialiseFirebaseApp = () => {
    app.initializeApp( {
        apiKey : process.env.REACT_APP_FIREBASE_API_KEY,
        projectId : process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'bb-checkpoint-dev',
        measurementId : process.env.REACT_APP_FIREBASE_EASUREMENT_ID,
        appId : process.env.REACT_APP_FIREBASE_APP_ID
    } ).analytics()

    // console.log( "Firebase Analytics initialised for " + process.env.REACT_APP_FIREBASE_PROJECT_ID )
}

export default initialiseFirebaseApp
