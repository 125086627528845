import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Loading from "./components/Loading";
import { useAuth, UserProvider } from "./contexts/UserContext";

const Profiler = lazy(() => import("./pages/profiler"));
const NotFound = lazy(() => import("./pages/404"));

const PublicRoute = (props) => {
  const { userToken } = useAuth();

  if (userToken && ["/sign-in", "/sign-up"].includes(props.path)) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

function App() {
  return (
    <Suspense fallback={<Loading isFullScreen />}>
      <Router>
        <UserProvider>
          <Switch>
            <PublicRoute path="/" exact>
              <Profiler />
            </PublicRoute>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </UserProvider>
      </Router>
    </Suspense>
  );
}

export default App;
