import axios from "axios";
import cookie from "js-cookie";
import DefaultResponseHandler from "./ResponseHandler";
import {
  browserName,
  fullBrowserVersion,
  osVersion,
  osName,
} from "react-device-detect";
import qs from 'querystring';
const TOKEN_KEY = "customerToken";

const baseURL = process.env.REACT_APP_API_DOMAIN;
// const  baseURL = 'https://api.bennybutton.cloud/';
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  // "Set-Cookie": process.env.REACT_APP_API_COOKIE,
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true
};

const apiInstance = axios.create({
  baseURL,
  headers,
});

const apiWithKey = axios.create({
  baseURL,
  headers: {
    ...headers,
    "BB-API-ACCESS-KEY": process.env.REACT_APP_BB_API_ACCESS_KEY,
  },
});

const apiController = "/v1/";
const API_USER = () => `${apiController}user`;
const API_SEND_OS_INFO = () => `${apiController}session/audit`;
const API_GET_BENCHMARK = () => `${apiController}analytics/modular-profiler-benchmark-data`;
const API_GET_REALTIME_CHECKIN = (myToken) => `${apiController}realtime-check-in/${myToken}`;

let responseHandler;
const defaultResponseHandler = new DefaultResponseHandler();

/**
 * Utilities
 */

export const getAuthToken = () => cookie.get(TOKEN_KEY);

const setAuthorizationHeader = (token) => {
  if (!apiInstance.defaults.headers) {
    apiInstance.defaults.headers = {};
  }
  if (token) {
    apiInstance.defaults.headers.Authorization = `Bearer ${token}`;
    cookie.set(TOKEN_KEY, token, { expires: 2 });
  }
};

const removeAuthorizationHeader = () => {
  if (apiInstance.defaults.headers) {
    delete apiInstance.defaults.headers["Authorization"];
    cookie.remove(TOKEN_KEY);
  }
};

const setTemporaryResponseHandler = (handler) => {
  responseHandler = handler;
};

const executeRequest = (promise) => {
  return new Promise((resolve, reject) => {
    const handler =
      responseHandler && typeof responseHandler === "function"
        ? responseHandler
        : defaultResponseHandler;
    promise
      .then((response) => {
        handler.handleSuccess(response, resolve, reject);
        responseHandler = undefined;
      })
      .catch((error) => {
        // console.log({ error });
        handler.handleError(error, reject);
        responseHandler = undefined;

        if (error?.response?.status === 401) {
          removeAuthorizationHeader();
        }
      });
  });
};

const doGetRequest = (url, params, config) => {
  const api = apiWithKey
  let tierParam = {};
  let tierKey = "";
  if (params) {
    for (const i in params) {
      if (i.includes("tier")) {
        tierParam[i] = params[i];
        tierKey = i;
        delete params[i];
      }
    }
  }
  url += `?${qs.stringify(params)}`;
  if (tierKey) {
    for (const i in tierParam) {
      url = `${url}&${i}=${tierParam[i]}`;
      break;
    }
  }
  return executeRequest(api.get(url, config));
};

const doPostRequest = (url, payload, config) => {
  const api = config?.withKey ? apiWithKey : apiInstance;
  return executeRequest(api.post(url, payload, config));
};

// const doPutRequest = (url, payload, { withKey, signupKey } = {}) => {
//   const api = withKey ? apiWithKey : apiInstance;
//   const headers = signupKey ? { "BB-ORG-SIGNUP-KEY": signupKey } : {};

//   return executeRequest(api.put(url, payload, { headers }));
// };

/**
 * Authenicated
 */
const getUser = ({ userToken }) => {
  if (userToken) {
    setAuthorizationHeader(userToken);
  }

  return doGetRequest(API_USER());
};

const sendOSInfo = async (eventType) => {
  let latitude = 0;
  let longitude = 0;
  const coords = localStorage.getItem("coords");
  if (coords) {
    const data = JSON.parse(coords);
    latitude = data.latitude;
    longitude = data.longitude;
  }

  const param = {
    fcmToken: null,
    uniqueDeviceIdentification: "checkpoint desktop build version",
    deviceMsisdn: "",
    eventType,
    browserInformation: `${browserName} ${fullBrowserVersion}`,
    deviceOperatingSystem: `${osName} ${osVersion}`,
    latitude,
    longitude,
  };
  doPostRequest(API_SEND_OS_INFO(), param);
};

export const getBenchmark = () =>
  doGetRequest(API_GET_BENCHMARK());

export const getRealtimeCheckin = (myToken) =>
  doGetRequest(API_GET_REALTIME_CHECKIN(myToken));



/**
 * Authenicated
 */

const exported = {
  apiInstance,
  setTemporaryResponseHandler,
  setAuthorizationHeader,
  removeAuthorizationHeader,
  getUser,
  sendOSInfo,
  getBenchmark,
  getRealtimeCheckin
};

export default exported;
