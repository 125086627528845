import { atom, useAtom } from "jotai";

export const ProgressStates = {
  UP1: "UP1",
  UP1a: "UP1*",
  UP2: "UP2",
  UP3: "UP3",
  UP4: "UP4",
  UP5: "UP5",
  UP6: "UP6",
  UP7: "UP7",
};

export const progressUpdateAtom = atom(ProgressStates.UP6);

export function useProgressUpdate() {
  return useAtom(progressUpdateAtom);
}
